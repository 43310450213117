<template>
  <form class="form" novalidate="novalidate">
    <div class="row align-items-center mb-10">
      <label class="col-lg-3 col-xl-2 form-label fw-semi-bold mb-4 mb-lg-0">Designer</label>

      <div class="col-lg-9 col-xl-10">
        <px-select
          v-if="users.data"
          v-model="form.usersVal"
          :clear="true"
          placeholder="Select a designer"
          :items="users.data"
          multiple
          track-by="id"
          :text-by="['attributes.first_name', ' ', 'attributes.last_name']"
          select2-id="select2_user_ids"
        />
      </div>
    </div>

    <div class="d-flex flex-stack">
      <px-btn color="light" extended-classes="btn-active-light-primary me-2" @click.native="close">Cancel </px-btn>
      <px-btn :in-process="submitInProcess" :disabled="submitInProcess" @click.native="onSubmit">Save </px-btn>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  props: {
    proposalId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        usersVal: [],
      },
      showModal: false,

      submitInProcess: false,

      initialUsers: [],
    };
  },
  computed: {
    ...mapGetters({
      message: 'proposalWizard/message',
      users: 'users/users',
      selectedUsers: 'proposalWizard/users',
    }),
    fullName(fn, ln) {
      return `${fn} ${ln}`;
    },
  },
  async mounted() {
    await this.usersInit({ groups: ['Designer'], fields: 'first_name,last_name,avatar' });

    if (this.selectedUsers.length) {
      const newIds = [];

      this.selectedUsers.map((el) => {
        newIds.push(el.id);
      });

      this.form.usersVal = newIds;
      this.initialUsers = newIds;
    }
  },
  methods: {
    ...mapActions({
      usersInit: 'users/index',
      megaUpdate: 'proposalWizard/megaUpdate',
    }),
    ...mapMutations({
      setUsers: 'proposalWizard/SET_USERS',
      setUserIds: 'proposalWizard/SET_USER_IDS',
    }),
    openModal() {
      this.showModal = true;
    },
    defaultValues() {
      this.form = {
        usersVal: [],
      };
    },
    async initForm() {
      await this.defaultValues();

      this.$emit('onClose', true);
    },
    close() {
      this.defaultValues();
      this.$emit('onClose', true);
    },
    async onSubmit() {
      this.submitInProcess = true;

      const newUsers = this.users.data.filter((el) => {
        return this.form.usersVal.includes(el.id);
      });
      const newUserIds = [];

      newUsers.map((el) => {
        newUserIds.push(el.id);
      });

      if (JSON.stringify([...newUserIds.sort()]) === JSON.stringify([...this.initialUsers.sort()])) {
        this.close();

        return false;
      }

      this.setUsers(newUsers);
      this.setUserIds(newUserIds);

      await this.megaUpdate(this.proposalId);

      this.submitInProcess = false;

      await this.v_toast(this.message?.status, 'Updated successfully!', () => {
        this.initForm();
      });
    },
  },
};
</script>
