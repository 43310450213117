<template>
  <div ref="parent" class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
      <div class="d-flex flex-column flex-row-fluid wrapper">
        <!--begin::Toolbar-->
        <div class="toolbar py-5 py-lg-7">
          <!--begin::Container-->
          <div class="container-fluid d-flex flex-stack flex-wrap align-items-start">
            <!--begin::Page title-->
            <div class="page-title d-flex flex-column me-3">
              <h1 class="h2 text-white">
                {{ state === 'Change Order' ? 'Change Order' : 'New Proposal' }}: {{ scopeName }}
              </h1>
              <p class="fs-6 text-white opacity-75 mb-0" v-if="subject">{{ subject.data.attributes.address }}</p>
            </div>
            <!--end::Page title-->

            <!--begin::Actions-->
            <div class="d-flex">
              <div class="symbol-group me-9">
                <span
                  v-if="subject && subject.data.attributes.manager"
                  :class="['symbol symbol-35px symbol-circle', { 'me-4': users.length }]"
                  v-tooltip="{
                    content: `${subject.data.attributes.manager.data.attributes.first_name} ${subject.data.attributes.manager.data.attributes.last_name}`,
                    delay: { show: 400, hide: 0 },
                    placement: 'bottom',
                  }"
                >
                  <img
                    v-if="subject.data.attributes.manager.data.attributes.avatar"
                    :alt="`${subject.data.attributes.manager.data.attributes.first_name} ${subject.data.attributes.manager.data.attributes.last_name}`"
                    :src="subject.data.attributes.manager.data.attributes.avatar"
                  />
                  <span v-else class="symbol-label text-white fw-semi-bold bg-primary">
                    {{ subject.data.attributes.manager.data.attributes.first_name.charAt(0) }}
                    {{ subject.data.attributes.manager.data.attributes.last_name.charAt(0) }}
                  </span>
                </span>
                <div v-if="users.length" class="symbol-group">
                  <span
                    v-for="user in users"
                    :key="user.id"
                    class="symbol symbol-35px symbol-circle"
                    :title="`${user.attributes.first_name} ${user.attributes.last_name}`"
                    v-tooltip="{
                      content: `${user.attributes.first_name} ${user.attributes.last_name}`,
                      delay: { show: 400, hide: 0 },
                      placement: 'bottom',
                    }"
                    role="button"
                    @click.prevent="openModal"
                  >
                    <img
                      :src="user.attributes.avatar"
                      :alt="`${user.attributes.first_name} ${user.attributes.last_name}`"
                    />
                  </span>
                </div>
              </div>
              <div v-if="!users.length && state !== 'Change Order'" class="me-9">
                <button type="button" class="btn btn-sm btn-primary" @click.prevent="openModal">Set Designer</button>
              </div>
              <px-btn
                size="sm"
                color="success"
                extended-classes="text-white"
                :in-process="saveAndExitInProcess"
                :disabled="saveAndExitInProcess"
                @click.native="onSaveAndExit"
              >
                Save & Exit
              </px-btn>
            </div>
            <!--end::Actions-->
          </div>
          <!--end::Container-->
        </div>
        <!--end::Toolbar-->
        <!--begin::Container-->
        <div class="pb-10">
          <!--begin::Content-->
          <div class="content flex-row-fluid">
            <!--begin::Card-->
            <div class="card">
              <!--begin::Body-->
              <div class="card-body px-4">
                <!--begin::Stepper-->
                <div class="stepper stepper-pills py-lg-10">
                  <!--begin::Nav-->
                  <div class="stepper-nav flex-sm-center flex-wrap mb-5">
                    <!--begin::Step -->
                    <span
                      v-for="(item, index) in topMenu"
                      :key="index"
                      :class="['stepper-item mx-2 mx-lg-4 mb-5', { completed: isPassed(item.pathName) }]"
                      role="button"
                      @click="() => validAndGoTo(item)"
                    >
                      <span class="stepper-icon w-30px h-30px w-lg-40px h-lg-40px me-2 me-lg-4">
                        <i class="stepper-check fas fa-check" />
                        <span class="stepper-number">{{ index + 1 }}</span>
                      </span>
                      <span class="stepper-label">
                        <span class="stepper-title mb-1">{{ item.title }}</span>
                      </span>
                    </span>
                    <!--end::Step -->
                  </div>
                  <!--end::Nav-->

                  <router-view v-if="scopeType" :scope-type="scopeType" :item-id="itemId" />
                  <!--end::Form-->
                </div>
                <!--end::Stepper-->
              </div>
              <!--end: Card Body-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Content-->
        </div>
      </div>
    </div>

    <px-new-modal :is-visible="isSetProposalDesignerModalOpened" :close-modal="closeModal">
      <template #title>
        <h3>Set Designers</h3>
      </template>
      <template #content>
        <pxm-set-proposal-designer :proposal-id="itemId" @onClose="closeModal" />
      </template>
    </px-new-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ProposalWizardNavMixin from '@/mixins/ProposalWizardNavMixin';

import PxmSetProposalDesigner from '@/modals/PxmSetProposalDesigner';

export default {
  mixins: [ProposalWizardNavMixin],
  components: {
    PxmSetProposalDesigner,
  },
  data() {
    return {
      itemId: this.$router.history.current.params.id,
      current: this.$router.history.current.name,
      isSetProposalDesignerModalOpened: false,
      scopeName: null,

      saveAndExitInProcess: false,
    };
  },
  computed: {
    ...mapGetters({
      topMenu: 'proposalWizard/topMenu',
      state: 'proposalWizard/state',
      message: 'proposalWizard/message',
      scopeType: 'proposalWizard/scopeType',
      subject: 'proposalWizard/subject',
      users: 'proposalWizard/users',
      subTotalPercentage: 'proposalWizard/subTotalPercentage',
    }),
  },
  async mounted() {
    await this.initOverall({ id: this.itemId });
    await this.initCategories(this.scopeType);
    await this.requestUnits();
    await this.requestAttachedSpecifications();

    this.scopeName = this.scopeNameByParam(this.scopeType);

    document.body.classList.add('header-fixed', 'header-tablet-and-mobile-fixed', 'toolbar-enabled');
  },
  destroyed() {
    document.body.classList.remove('header-fixed', 'header-tablet-and-mobile-fixed', 'toolbar-enabled');
  },
  methods: {
    ...mapActions({
      initOverall: 'proposalWizard/initOverall',
      initCategories: 'proposalWizard/initCategories',
      megaUpdate: 'proposalWizard/megaUpdate',
      requestUnits: 'units/index',
      requestAttachedSpecifications: 'attachedSpecifications/index',
    }),
    isPassed(path) {
      let targetInd = 0;
      let currentInd = 0;

      this.topMenu.map((el, i) => {
        if (el.pathName === path) {
          targetInd = i;
        }
        if (el.pathName === this.$router.history.current.name) {
          currentInd = i;
        }
      });

      return targetInd <= currentInd;
    },
    openModal() {
      this.isSetProposalDesignerModalOpened = true;
    },
    closeModal() {
      this.isSetProposalDesignerModalOpened = false;
    },
    async onSaveAndExit() {
      this.saveAndExitInProcess = true;

      if (!this.checkIfProposalWasUpdated()) {
        this.saveAndExitInProcess = false;

        await this.$router.push({ name: 'single-project', params: { id: this.subject.data.id } });
      } else {
        await this.megaUpdate(this.itemId);
        await this.v_toast(this.message?.status, 'Updated successfully!', () => {
          this.saveAndExitInProcess = false;

          this.$router.push({ name: 'single-project', params: { id: this.subject.data.id } });
        });
      }
    },
    validAndGoTo(item) {
      // Validations
      if (this.$route.name === 'proposal-wizard.payment-details' && this.paymentDetailsValid() === false) {
        return false;
      }

      this.goToPath(item);
    },
    // Validations
    paymentDetailsValid() {
      if (parseInt(this.subTotalPercentage) !== 100) {
        this.$scrollTo('#payment-schedule', 400);

        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
#kt_body {
  background-image: url('/assets/media/header-bg.png');
}
</style>
<style scoped>
.step-content {
  display: block !important;
}

.add-item-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.475rem;
  width: 45px;
  height: 25px;
  z-index: 199;
  position: absolute;
  right: 18px;
  top: 8px;
}

.kt-items-list-item {
  position: relative;
}

.kt-items-list-item:hover .add-item-btn {
  opacity: 1;
}

.kt-items-list-item .additional-control {
  top: 12px;
}

.z-100 {
  z-index: 999 !important;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.dense-content .table tr:first-child,
.dense-content .table th:first-child,
.dense-content .table td:first-child {
  padding-left: 0rem !important;
}

.dense-content .table > :not(caption),
.dense-content :not([data-td-name='total-price']) > * > td {
  padding: 0.5rem 0.35rem !important;
}

.dense-content td[data-td-name='total-price'],
.dense-content td[data-td-name='actions'] {
  padding-top: 20px !important;
}

.mw-fit-content {
  max-width: fit-content;
}

.fs-16px {
  font-size: 16px !important;
}

/* Stepzer Custom Styles[TEMP] */
.stepper-number {
  display: inline-block !important;
}

.stepper-check {
  display: none !important;
}

.stepper-item.current .stepper-icon {
  background-color: #00a3ff !important;
}

.stepper-item.current .stepper-title {
  color: #000000 !important;
}

.stepper-item.completed .stepper-icon {
  background-color: #00a3ff !important;
}

.stepper.stepper-pills .stepper-item.completed .stepper-icon .stepper-number {
  color: #fff !important;
}

.stepper-item.completed .stepper-number {
  color: #fff !important;
}

.stepper-item.completed .stepper-title {
  color: #000000 !important;
}
</style>
